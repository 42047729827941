import icons from 'url:../../img/icons.svg';
import View from './view';

class PaginationView extends View {
  _parentElement = document.querySelector('.pagination');

  addHandlerClick(handler) {
    this._parentElement.addEventListener('click', function (e) {
      const btn = e.target.closest('.btn--inline');
      if (!btn) return;
      const goToPage = +btn.dataset.goto;
      handler(goToPage);
    });
  }

  _generateMarkup() {
    const currentPage = this._data.page;
    // Get Number of Pages.
    const numOfPages = Math.ceil(
      this._data.result.length / this._data.resultPerPage
    );

    // If Page 1
    if (currentPage < numOfPages && currentPage == 1)
      return `<button data-goto=${
        currentPage + 1
      } class="btn--inline pagination__btn--next">
      <span>Page ${currentPage + 1}</span>
      <svg class="search__icon">
        <use href="${icons}#icon-arrow-right"></use>
      </svg>
    </button>`;
    // If Last Page
    if (currentPage === numOfPages && numOfPages > 1)
      return `<button data-goto=${
        currentPage - 1
      }  class="btn--inline pagination__btn--prev">
      <svg class="search__icon">
        <use href="${icons}#icon-arrow-left"></use>
      </svg>
      <span>Page ${currentPage - 1}</span>
    </button>`;
    // Other Pages
    if (currentPage < numOfPages && currentPage > 1)
      return `<button data-goto=${
        currentPage - 1
      }  class="btn--inline pagination__btn--prev">
    <svg class="search__icon">
      <use href="${icons}#icon-arrow-left"></use>
    </svg>
    <span>Page ${currentPage - 1}</span>
  </button>
  <button data-goto=${
    currentPage + 1
  }  class="btn--inline pagination__btn--next">
      <span>Page ${currentPage + 1}</span>
      <svg class="search__icon">
        <use href="${icons}#icon-arrow-right"></use>
      </svg>
    </button>
  `;

    // 1 Page only..
    if (numOfPages === 1) return ``;
  }

  _generateMarkupPreview(result) {
    return ``;
  }
}

export default new PaginationView();
