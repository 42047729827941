import recipeView from './views/recipeView';
// to Support E6 for old Browsers...
import 'core-js';
// to Support E6 of Async Await
import 'regenerator-runtime/runtime';
import * as model from './model';
import { async } from 'regenerator-runtime/runtime';
import searchView from './views/searchView';
import resultsView from './views/resultsView';
import paginationView from './views/paginationView';
import bookmarksView from './views/bookmarksView';
import addRecipeView from './views/addRecipeView';
import { MODAL_CLOSE_SEC } from './config';

// const parentElement = document.querySelector('.recipe');

// https://forkify-api.herokuapp.com/v2

///////////////////////////////////////

// API Fetch rec
const controlRecipes = async function () {
  try {
    const id = window.location.hash.slice(1);

    // Guard Clause.
    if (!id) return;
    // Loading Spinner.
    recipeView.renderSpinner();
    // 0- Update Results View to mark selected Search result.
    resultsView.update(model.getSearchResultsPage());
    bookmarksView.update(model.state.bookmark);
    // 1- Load Recipe

    await model.loadRecipe(id);

    // 2- Render Ricepe
    recipeView.render(model.state.recipe);
  } catch (err) {
    recipeView.renderError();
  }
};

const controlSearchResults = async function () {
  try {
    // 1- Getting the Input value.
    const query = searchView.getQuery();

    // Guard Clause.

    if (!query) return;
    resultsView.renderSpinner();

    // 2 Load Search Result
    await model.loadSearchResults(query);

    // 3 Render Result
    resultsView.render(model.getSearchResultsPage());

    paginationView.render(model.state.search);
  } catch (err) {
    throw err;
  }
};

const controlPaginationView = function (goTo) {
  // 1- Go To Page..
  resultsView.render(model.getSearchResultsPage(goTo));
  // 2- Render new Page
  paginationView.render(model.state.search);
};

const controlServings = function (updateServingsTo) {
  // Update The Recipe Servings in State
  model.updateServings(updateServingsTo);
  // Update the recipe view
  // 2- Update Ricepe Servings
  recipeView.update(model.state.recipe);
};

const controlAddBookmark = function () {
  // 1- Add/remove Book Mark
  if (!model.state.recipe.bookmarked) model.addBookMark(model.state.recipe);
  else model.delBookMark(model.state.recipe.id);
  // 2- Update recipe View
  recipeView.update(model.state.recipe);
  // 3- Render Bookmarks menu
  bookmarksView.render(model.state.bookmark);
};

const controlBookmarksLoad = function () {
  bookmarksView.render(model.state.bookmark);
};

const controlAddRecipe = async function (newRecipe) {
  try {
    // Show loading spinner
    addRecipeView.renderSpinner();

    // Upload the new recipe data
    await model.uploadRecipe(newRecipe);
    console.log(model.state.recipe);

    // Render recipe
    recipeView.render(model.state.recipe);

    // Success message
    addRecipeView.rendersucess();

    // Render bookmark view
    bookmarksView.render(model.state.bookmark);

    // Change ID in URL
    window.history.pushState(null, '', `#${model.state.recipe.id}`);

    // Close form window
    setTimeout(function () {
      addRecipeView.toggleWindow();
    }, MODAL_CLOSE_SEC * 1000);
  } catch (err) {
    console.error('💥', err);
    addRecipeView.renderError(err.message);
  }
};

const init = () => {
  bookmarksView.addHandlerRender(controlBookmarksLoad);
  recipeView.addHandlerRender(controlRecipes);
  searchView.addHandlerSearch(controlSearchResults);
  recipeView.addHandlerBookmark(controlAddBookmark);
  paginationView.addHandlerClick(controlPaginationView);
  recipeView.addHandlerUpdateServings(controlServings);
  addRecipeView.addHandlerUpload(controlAddRecipe);
};
init();
